import React, { useState, useMemo } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Label } from '@radix-ui/react-label';
import { Circle } from 'lucide-react';
import { mergeCSSClasses } from './shadcnUtils';

type LimitedStringConvertible = string | number | boolean;
type RadioButtonOrientation = 'horizontal' | 'vertical';

export function useRadioButton<T extends LimitedStringConvertible>(
  values: T[],
  defaultSelected: T,
  orientation: RadioButtonOrientation = 'horizontal',
) {
  const [selected, setSelected] = useState<T>(defaultSelected);

  const RadioButtonsT = useMemo(
    () => () => (
      <RadioGroupPrimitive.Root
        value={selected.toString()}
        onValueChange={(value) => setSelected(value as T)}
        className={`flex ${orientation === 'horizontal' ? 'flex-row space-x-4' : 'flex-col space-y-2'}`}
      >
        {values.map((option) => (
          <div className="flex items-center space-x-2" key={option.toString()}>
            <RadioGroupPrimitive.Item
              value={option.toString()}
              id={`radio-${option.toString()}`}
              className={mergeCSSClasses(
                'aspect-square h-4 w-4 rounded-full border border-uicore-contrast text-uicore-contrast shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
              )}
            >
              <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
                <Circle className="h-2.5 w-2.5 fill-current text-current" />
              </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
            <Label htmlFor={`radio-${option.toString()}`}>
              {option.toString()}
            </Label>
          </div>
        ))}
      </RadioGroupPrimitive.Root>
    ),
    [values, selected, orientation],
  );

  return {
    selected,
    RadioButtonsT,
  };
}
